#video {
    position: absolute;
    z-index: -1;
    width: 100%;
    filter: blur(1.5px) brightness(0.85);
    object-fit:cover;
    height:inherit
}

.stack-for-background {
    background: rgb(255,255,255);
background: -moz-radial-gradient(circle, rgba(255,255,255,1) 55%, rgba(218,230,255,1) 100%);
background: -webkit-radial-gradient(circle, rgba(255,255,255,1) 55%, rgba(218,230,255,1) 100%);
background: radial-gradient(circle, rgba(255,255,255,1) 55%, rgba(218,230,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dae6ff",GradientType=1);
}
.text-home {
    color: white;
    text-shadow: 0.1rem 0.1rem 2px black;
    text-align:center
}
@media screen and (max-width:768px){
    
}
@media screen and (max-height:568px){
    .home{
        margin-top: 0 !important;
    }
}
.help-button{
    animation: scaler 0.8s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes scaler {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}